<template>
  <div class="main-page">
    <template v-if="showHeader">
      <section class="page-section mb-3">
        <div class="container-fluid">
          <div class="grid justify-content-between">
            <div class="col-12 md:col-12">
              <div class="">
                <div class="flex align-items-center">
                  <div>
                    <Avatar size="large" class="primary" icon="pi pi-plus" />
                  </div>
                  <div class="ml-2">
                    <div class="text-2xl text-primary font-bold">Add News</div>
                    <div class="text-sm text-500">Create news record</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <hr />
    </template>
    <section class="page-section">
      <div class="container">
        <div class="grid">
          <div class="md:col-8 sm:col-12 comp-grid">
            <div class="card nice-shadow-6">
              <div>
                <form
                  ref="observer"
                  tag="form"
                  @submit.prevent="startSaveRecord()"
                  @reset="resetForm"
                >
                  <div class="grid">
                    <div class="col-12">
                      <div class="mb-2 font-bold text-sm">Title *</div>
                      <div id="ctrl-title-holder">
                        <InputText
                          ref="ctrltitle"
                          v-model.trim="formData.title"
                          label="Title"
                          type="text"
                          placeholder=" "
                          class="w-full"
                          :class="getErrorClass('title')"
                        >
                        </InputText>
                      </div>
                    </div>
                    <div class="col-12 md:col-6">
                      <div class="mb-2 font-bold text-sm">Subtitle</div>
                      <div id="ctrl-subtitle-holder">
                        <InputText
                          ref="ctrlsubtitle"
                          v-model.trim="formData.subtitle"
                          label="Subtitle"
                          type="text"
                          placeholder=" "
                          class="w-full"
                          :class="getErrorClass('subtitle')"
                        >
                        </InputText>
                      </div>
                    </div>
                    <div class="col-12 md:col-6">
                      <div class="mb-2 font-bold text-sm">Source Of News</div>
                      <div id="ctrl-source_of_news-holder">
                        <InputText
                          ref="ctrlsource_of_news"
                          v-model.trim="formData.source_of_news"
                          label="Source Of News"
                          type="text"
                          placeholder=" "
                          class="w-full"
                          :class="getErrorClass('source_of_news')"
                        >
                        </InputText>
                      </div>
                    </div>
                    <div class="col-12 md:col-6">
                      <div class="mb-2 font-bold text-sm">Category *</div>
                      <div id="ctrl-category-holder">
                        <Dropdown
                          class="w-full"
                          :class="getErrorClass('category')"
                          optionLabel="label"
                          optionValue="value"
                          ref="ctrlcategory"
                          v-model="formData.category"
                          :options="$menus.categoryItems"
                          label="Category"
                          placeholder="Select ..."
                        >
                        </Dropdown>
                      </div>
                    </div>
                    <div class="col-12 md:col-6">
                      <div class="mb-2 font-bold text-sm">Tag *</div>
                      <div id="ctrl-tag-holder">
                        <MultiSelect
                          class="w-full"
                          :class="getErrorClass('tag')"
                          optionLabel="label"
                          optionValue="value"
                          ref="ctrltag"
                          v-model="formData.tag"
                          :options="$menus.tagItems"
                          label="Tag"
                        >
                        </MultiSelect>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="mb-2 font-bold text-sm">Body *</div>
                      <div id="ctrl-body-holder">
                        <div class="mb-3" :class="getErrorClass('body')">
                          <Editor
                            label="Body"
                            ref="ctrlbody"
                            placeholder=" "
                            v-model="formData.body"
                            editorStyle="height: 120px"
                          ></Editor>
                          <!-- <small class="p-3 text-negative" v-if="invalid && validated">{{errors[0]}}</small> -->
                        </div>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="mb-2 font-bold text-sm">Header Image *</div>
                      <div id="ctrl-header_image-holder">
                        <div class="mb-3">
                          <Uploader
                            :class="getErrorClass('header_image')"
                            upload-path="s3uploader/upload/header_image"
                            v-model="formData.header_image"
                            :fileLimit="1"
                            :maxFileSize="3000000"
                            accept=".jpg,.png,.gif,.jpeg"
                            :multiple="false"
                            label="Choose files or drop files here"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="showSubmitButton" class="text-center my-3">
                    <Button
                      class="p-button-primary"
                      type="submit"
                      label="Submit"
                      icon="pi pi-send"
                      :loading="saving"
                    />
                  </div>
                </form>
                <slot :submit="submit" :saving="saving"></slot>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
//[--PAGE-IMPORT-STATEMENT--]
import { PageMixin } from "../../mixins/page.js";
import { AddPageMixin } from "../../mixins/addpage.js";
import { mapActions, mapGetters, mapState } from "vuex";
import useVuelidate from "@vuelidate/core";
import {
  required,
  email,
  sameAs,
  minLength,
  maxLength,
  minValue,
  maxValue,
  numeric,
  integer,
  decimal,
  ipAddress,
} from "@vuelidate/validators";
export default {
  setup() {
    return { v$: useVuelidate() };
  },
  name: "addNewsPage",
  components: {
    //[--PAGE-COMPONENT-NAME--]
  },
  mixins: [PageMixin, AddPageMixin],
  props: {
    pageName: {
      type: String,
      default: "news",
    },
    routeName: {
      type: String,
      default: "newsadd",
    },
    apiPath: {
      type: String,
      default: "news/add",
    },
  },
  data() {
    return {
      formData: {
        title: "",
        subtitle: "",
        category: "",
        tag: [],
        body: "",
        source_of_news: "",
        header_image: "",
      },
    };
  },
  computed: {
    pageTitle: {
      get: function () {
        return "Add News";
      },
    },
  },
  validations() {
    let formData = {
      title: { required },
      subtitle: {},
      category: { required },
      tag: { required },
      body: { required },
      source_of_news: {},
      header_image: { required },
    };
    return { formData };
  },
  methods: {
    ...mapActions("news", ["saveRecord"]),
    async startSaveRecord() {
      this.submitted = true;
      const isFormValid = !this.v$.$invalid;
      if (!isFormValid) {
        this.flashMsg("Form is invalid", "", "error");
        return;
      }
      this.saving = true;
      let payload = this.normalizedFormData();
      let url = this.apiUrl;
      let data = { url, payload };
      this.saveRecord(data).then(
        (response) => {
          this.record = response.data ?? {};
          this.id = this.record["news_id"] ?? null;
          this.saving = false;
          this.resetForm();
          this.submitted = false;
          this.closeDialogs(); // close page dialog that if opened
          this.flashMsg(this.msgAfterSave);
          this.$emit("submitted", this.record);
          if (this.redirect) this.navigateTo(`/news`);
        },
        (response) => {
          this.saving = false;
          this.showPageRequestError(response);
        }
      );
    },
    resetForm() {
      this.formData = {
        title: "",
        subtitle: "",
        category: "",
        tag: [],
        body: "",
        source_of_news: "",
        header_image: "",
      };
      this.updateFormData();
      //this.$EventBus.$emit("resetForm");
    },
    getErrorClass(field) {
      return {
        "p-invalid": this.v$.formData[field].$invalid && this.submitted,
      };
    },
  },
  watch: {},
  async mounted() {},
  async created() {},
};
</script>
<style scoped></style>
<style scoped></style>
